import React, { Component } from 'react';
import { from } from 'rxjs'
// import { fromPromise } from 'rxjs/operators'

import AppContext from './context'
import __ from '../i18n'
import Loading from './loading'


class Query extends Component {
    state = {
        // subscriptionData: null,
        isLoading: false,
        error: null,
        result: undefined,
    }


    componentWillMount(){
        this.setState({
            loading: true
        })

        this.queryData()
    }


    cleanup(){
        this.querySub && this.querySub.unsubscribe()
        this.subscription && this.subscription.unsubscribe()
    }


    componentWillUnmount(){
        this.cleanup()
    }


    queryData = () => {
        this.cleanup()

        this.querySub = from(
            this.props.app.gql.query(this.props.gql, this.props.options)
        ).subscribe(
            result => {
                this.setState({
                    isLoading: false,
                    error: null,
                    result
                })
            },
            error => {
                this.setState({
                    isLoading: false,
                    error
                })
            }
        )

        if(this.props.subscribe === true){
            this.subscription = this.props.app.gql.subscribe(this.props.gql, this.props.options)
        }
    }


    render(){
        if(this.state.isLoading || this.state.result === undefined){
            return React.createElement(this.props.loading)
        }

        const refetch = () => {
            this.setState({
                isLoading: true,
                error: null
            })

            return this.queryData()
        }

        if(this.state.error){
            return React.createElement(this.props.error, {
                error: this.state.error,
                refetch
            })
        }

        return this.props.children(this.state.result, refetch)
    }

}


Query.defaultProps = {
    gql: null,
    subscribe: false,
    options: {},
    loading: Loading,
    error: () => (
        <div className="alert alert-danger text-center">
            { __('Failed to load data, click to retry.') }
        </div>
    )
}


export default (props) => (
    <AppContext.Consumer>
        { ({app}) => (
            <Query {...props} app={app} />
        ) }
    </AppContext.Consumer>
);