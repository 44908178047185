import React, { Component } from 'react';

const Context = React.createContext(null);

const withContext = Element => {
	return class extends Component {
		static navigationOptions = Element.navigationOptions;

		render() {
			return (
				<Context.Consumer>
					{context =>
						React.createElement(Element, { ...this.props, context })
					}
				</Context.Consumer>
			);
		}
	};
};

export { withContext, Context };

export default Context;
