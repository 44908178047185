import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faUsers,
  faEye
  // faUserShield,
  // faUserFriends
} from "@fortawesome/free-solid-svg-icons";
import { sortBy, get, map } from "lodash";
import "lightbox2/dist/css/lightbox.min.css";
import "lightbox2/dist/js/lightbox.min.js";

import "./assets/css/theme.scss";
import AppFactory from "./appfactory/react";
import logo from "./assets/img/logo.png";
import Loadable from "./appfactory/react/loadable";
import Loading from "./appfactory/react/loading";
import Query from "./appfactory/react/query";
import app from "./appfactory";
import { __, getLocale, addLocalesData } from "./appfactory/i18n";

const locales = {
  fr: "Français",
  en: "English"
};
const curLocale = getLocale();

const curTheme = localStorage.getItem("theme");
if (curTheme) {
  document.body.className = `theme-${curTheme}`;
}

class App extends Component {
  state = {
    isReady: false
  };

  componentWillMount() {
    let todo = [];

    if (localStorage.getItem("token")) {
      todo.push(
        app.gql.query(`{ config }`).then(({ data: { config } }) => {
          window.config = config;

          app.store.setState({
            config,
            name: config.app_name || app.store.store.name
          });
        })
      );

      todo.push(
        app.gql
          .query(
            `{
						account {
							id
							logo {
								id
								thumb
							}
					}
				}`
          )
          .then(({ data: { account } }) => (this.account = account))
      );
    }

    switch (getLocale()) {
      case "en":
        todo.push(
          import("./appfactory/i18n/en").then(locales =>
            addLocalesData(locales.default)
          )
        );
        break;
      case "fr":
        todo.push(
          import("./appfactory/i18n/fr").then(locales =>
            addLocalesData(locales.default)
          )
        );
        todo.push(
          import("./i18n/fr").then(locales => addLocalesData(locales.default))
        );
        break;
      case "ar":
        todo.push(
          import("./appfactory/i18n/ar").then(locales =>
            addLocalesData(locales.default)
          )
        );
        todo.push(
          import("./i18n/ar").then(locales => addLocalesData(locales.default))
        );
        break;
      default:
        break;
    }

    // Init
    Promise.all(todo)
      .then(() => {
        this.setState({ isReady: true });
      })
      .catch(e => {
        console.error("Failed to init app", e);
      });
  }

  render() {
    if (!this.state.isReady) {
      return <Loading />;
    }

    return (
      <AppFactory
        app={app}
        authRequired={true}
        logo={logo}
        avatar={get(
          this.account,
          "logo.thumb",
          get(app.auth.user, "picture.thumb")
        )}
        onAuth={user => {
          window.location.reload(true);
          // console.log('Authenticated', user);
        }}
        menuHeader={
          <ul className="navbar-nav mr-auto"></ul>

          // {
          // 	label: 'Dashboard',
          // 	href: '/'
          // },
          // {
          // 	label: 'Test',
          // 	href: '/test'
          // }
        }
        menuSidebar={
          <ul className="nav flex-column flex-grow-1">
            <li className="nav-item">
              <NavLink exact className="nav-link" to="/">
                <FontAwesomeIcon icon={faTachometerAlt} /> {__("Dashboard")}
              </NavLink>
            </li>
            {get(app, "store.store.config.apps", []).includes("citizen") && (
              <li className="nav-item">
                <NavLink className="nav-link" to="/citizen">
                  <FontAwesomeIcon icon={faUsers} /> {__("Citizen reports")}
                </NavLink>
              </li>
            )}
            {get(app, "store.store.config.apps", []).includes("veille") && (
              <NavLink className="nav-link" to={`/veille`} key={`menu-veille`}>
                <FontAwesomeIcon icon={faEye} /> {__("Monitoring")}
              </NavLink>
            )}
            {get(app, "store.store.config.apps", []).includes("audit") && (
              <Query
                gql={`{
								audits {
									id
									name
									slug
									order
									icon {
										id
										url
									}
								}
							}`}
              >
                {({ data: { audits } }) =>
                  sortBy(audits, audit => audit.order).map(audit => (
                    <li className={`nav-item`} key={`menu-${audit.id}`}>
                      <NavLink
                        className="nav-link"
                        to={`/audits/${audit.slug}`}
                      >
                        <img
                          src={audit.icon.url}
                          alt={audit.name}
                          style={{
                            width: "20px"
                          }}
                        />{" "}
                        {audit.name}
                      </NavLink>
                    </li>
                  ))
                }
              </Query>
            )}
          </ul>
        }
        menuUser={
          <React.Fragment>
            {get(app, "store.store.config.apps", []).includes("admin") && (
              <React.Fragment>
                <NavLink className="dropdown-item" to="/admin/accounts">
                  Administration
                </NavLink>
                <NavLink className="dropdown-item" to="/admin/citizen">
                  Liste Citizen
                </NavLink>

                <div className="dropdown-divider" />
              </React.Fragment>
            )}

            {get(app, "store.store.config.apps", []).includes("cms") && (
              <Query
                gql={`{
									cmsTypes {
										id
										name
										isEnabled
									}
						}`}
              >
                {({ data: { cmsTypes } }) =>
                  cmsTypes
                    .filter(item => item.isEnabled === true)
                    .map(item => (
                      <NavLink
                        className="dropdown-item"
                        to={`/cms/${item.id}`}
                        key={`menu-${item.id}`}
                      >
                        {item.name}
                      </NavLink>
                    ))
                }
              </Query>
            )}

            <div className="dropdown-divider" />

            <NavLink className="dropdown-item" to="/profile">
              {__("Profile")}
            </NavLink>
            <NavLink className="dropdown-item" to="/settings/users">
              {__("Settings")}
            </NavLink>
            <div className="dropdown-divider" />
            <a
              href="/"
              className="dropdown-item"
              onClick={e => {
                e.preventDefault();
                app.auth.logout();
              }}
            >
              {__("Sign out")}
            </a>
          </React.Fragment>
        }
        footer={
          <footer>
            <div className="row">
              <div className="col-md-6">&copy; 2019 Master Audit</div>
              <div className="col-md-6 text-right">
                <span className="d-inline-block mr-3">
                  <button
                    style={{
                      border: "2px solid #fff",
                      width: "20px",
                      height: "20px",
                      background: "#343a40",
                      cursor: "pointer",
                      display: "inline-block",
                      margin: "0 2px"
                    }}
                    onClick={e => {
                      e.preventDefault();
                      localStorage.setItem("theme", "black");
                      window.location.reload();
                    }}
                  />
                  <button
                    style={{
                      border: "2px solid #fff",
                      width: "20px",
                      height: "20px",
                      background: "#4d4c92",
                      cursor: "pointer",
                      display: "inline-block",
                      margin: "0 2px"
                    }}
                    onClick={e => {
                      e.preventDefault();
                      localStorage.setItem("theme", "purple");
                      window.location.reload();
                    }}
                  />
                </span>

                <div className="dropdown d-inline-block">
                  <button
                    className="btn btn-sm btn-secondary dropdown-toggle"
                    type="button"
                    id="localeDropdown"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {locales[curLocale]}
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="localeDropdown"
                  >
                    {map(locales, (label, locale) => (
                      <button
                        className={`dropdown-item ${
                          locale === curLocale ? "active" : ""
                        }`}
                        key={locale}
                        onClick={() => {
                          localStorage.setItem("locale", locale);
                          window.location.reload();
                        }}
                      >
                        {label}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </footer>
        }
        routes={[
          {
            path: "/",
            exact: true,
            component: Loadable(() => import("./pages/dashboard"))
          },
          {
            path: "/audits/:type",
            exact: true,
            component: Loadable(() => import("./pages/audits"))
          },
          {
            path: "/audits/:type/create",
            exact: true,
            component: Loadable(() => import("./pages/audits/save"))
          },
          {
            path: "/audits/:type/edit/:id",
            exact: true,
            component: Loadable(() => import("./pages/audits/save"))
          },
          {
            path: "/audits/:type/:id",
            exact: true,
            component: Loadable(() => import("./pages/audits/view"))
          },
          {
            path: "/cms/:typeId",
            // exact: true,
            component: Loadable(() => import("./pages/cms/index"))
          },
          {
            path: "/citizen",
            exact: true,
            component: Loadable(() => import("./pages/citizen"))
          },
          {
            path: "/profile",
            // exact: true,
            component: Loadable(() => import("./pages/profile"))
          },
          {
            path: "/settings",
            // exact: true,
            component: Loadable(() => import("./pages/settings"))
          },
          {
            path: "/users/view/:id",
            // exact: true,
            component: Loadable(() => import("./pages/users/view"))
          },
          {
            path: "/admin",
            // exact: true,
            component: Loadable(() => import("./pages/admin"))
          },
          {
            path: "/veille",
            // exact: true,
            component: Loadable(() => import("./pages/veille"))
          }
        ]}
      />
    );
  }
}

export default App;
