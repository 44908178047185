import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import AppContext from '../context'
import Loadable from '../loadable' 
import logo from '../../assets/img/logo.svg'


const AuthPage = styled.div`
    height: 100%;
    background: var(--primary);
    background: -moz-radial-gradient(center, ellipse cover, var(--primaryLightened) 0%, var(--primary) 110%);
    background: -webkit-radial-gradient(center, ellipse cover, var(--primaryLightened) 0%, var(--primary) 110%);
    background: radial-gradient(600px at center, var(--primaryLightened) 0%, var(--primary) 110%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--primaryLightened)', endColorstr='var(--primary)',GradientType=1 );

    h1 {
        text-align: center;
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: normal;
        margin-bottom: 20px;
    }

    .btn {
        width: 100%;
        text-transform: uppercase;
    }

    .logo-container {
        .app-name {
            font-size: 1.4rem;
            color: #fff;
        }
    }

    .logo {
        height: 80px;
    }

    .auth-box {
        border-radius: 5px;
        background: #fff;
        padding: 30px;
        width: 450px;
        max-width: 100%;

        a {
            color: #787E8C;
            font-size: 0.9rem;
        }
    }

    /* Small devices (landscape phones, 576px and up) */
    @media (max-width: 575.98px) {
        .auth-box {
            width: 100%;
        }
    }

    /* Medium devices (tablets, 768px and up) */
    @media (min-width: 576px) and (max-width: 767.98px) {
        
    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 768px) and (max-width: 991.98px) {

    }
`


class Auth extends Component {
    render() {
        return (
            <AppContext.Consumer>
                { ctx => (
                    <AuthPage id="page-auth" className="container-fluid d-flex flex-column align-items-center justify-content-center" context={ctx}>

                        <div className="logo-container d-flex flex-row align-items-center justify-content-center mb-3">
                            <img src={ctx.logo || logo} alt={ctx.app.store.get('name')} className="logo" />
                            <h3 className="d-inline-block ml-2 app-name">{ ctx.app.store.get('name') }</h3>
                        </div>
                        

                        <div className="auth-box">
                            <Switch>
                                <Route exact path='/auth/forgot' component={Loadable(() => import('./forgot'))}/>
                                <Route exact path='/auth/register' component={Loadable(() => import('./register'))}/>
                                <Route component={Loadable(() => import('./login'))}/>
                            </Switch>
                        </div>
                    </AuthPage>
                )}
            </AppContext.Consumer>
        );
    }
}


export default Auth