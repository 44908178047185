import { ApolloLink, split } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { getMainDefinition } from 'apollo-utilities'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { persistCache } from 'apollo-cache-persist'
import { ApolloClient } from 'apollo-client'
import gql from 'graphql-tag'
import { from } from 'rxjs'
import { merge, map } from 'rxjs/operators'


export default class GraphQL {
    client = null
    fetchPolicy = 'network-first'
    httpLink = null
    authLink = null
    headers = {}
    wsLink = null
    errorLink = null
    link = null
    cache = new InMemoryCache()

    constructor(options = {}){
        this.headers = options.headers || {}
        this.fetchPolicy = options.fetchPolicy || 'network-first'

        if(options.cacheStorage){
            persistCache({
                cache: this.cache,
                storage: options.cacheStorage,
            })
        }

        this.httpLink = new HttpLink({
            uri: options.url
        })
        
        this.authLink = setContext((_, { headers }) => {
            return {
                headers: {
                    ...headers,
                    ...this.headers
                }
            }
        })
    
        this.errorLink = onError(options.onError || (() => {}))

        if(options.subscriptions){
            this.wsLink = new WebSocketLink({
                uri: options.subscriptions,
                options: {
                    reconnect: true,
                    connectionParams: this.headers,
                }
            })

            this.link = split(
                // split based on operation type
                ({ query }) => {
                    const { kind, operation } = getMainDefinition(query);
                    return kind === 'OperationDefinition' && operation === 'subscription';
                },
                this.wsLink,
                this.authLink.concat(this.httpLink),
            )
        
            this.client = new ApolloClient({
                link: ApolloLink.from([
                    this.errorLink,
                    this.link
                ]),
                cache: this.cache
            })
        }
        else {
            this.client = new ApolloClient({
                link: ApolloLink.from([
                    this.errorLink,
                    this.authLink.concat(this.httpLink)
                ]),
                cache: this.cache
            })
        }
    }


    query = (gqlStr, options = {}) => {
        return this.client.query({
            query: gql`${ gqlStr }`,
            fetchPolicy: options.fetchPolicy || this.fetchPolicy,
            ...options
        })
        // .then(result => {
        //     console.log('GQL result', result);
        //     return result
        // })
    }

    mutate = (gqlStr, options = {}) => {
        return this.client.mutate({
            mutation: gql`${ gqlStr }`,
            ...options
        })
    }

    subscribe = (gqlStr, options = {}) => {
        return this.client.subscribe({
            query: gql`${ gqlStr }`,
            fetchPolicy: options.fetchPolicy || this.fetchPolicy,
            ...options
        })
    }

    queryAndSubscribe = (gqlStr, options = {}) => {
        return from(this.query(gqlStr, options))
        .pipe(merge(this.subscribe(`subscription ${ gqlStr }`, options)))
        .pipe(map(({data}) => data))
    }
}