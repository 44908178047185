import { Subject } from 'rxjs'
import { startWith, filter, map } from 'rxjs/operators'

export default class Store {
    // store = {}

    constructor(store = {}){
        this.store = store
        this.subject = new Subject()
    }

    get(key){
        return this.store[key]
    }

    getStore(){
        return this.store
    }

    setState(newState){
        this.store = {
            ...this.store,
            ...newState
        }

        this.subject.next(newState)
    }

    watch(key, startWithOldValue = true){
        let source = this.subject
            .pipe(
                filter(newState => typeof newState === 'object' && newState.hasOwnProperty(key))
            )
            .pipe(
                map(newState => newState[key])
            )
        
        if(startWithOldValue === true && this.store[key]){
            source = source.pipe(startWith(this.store[key]))
        }

        return source
    }

    delete(key){
        delete(this.store[key])
        this.subject.next({[key]: undefined})
    }
}