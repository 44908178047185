import axios from 'axios'
import { from } from 'rxjs'
// import md5 from 'crypto-js/md5'
// import PouchDB from 'pouchdb-browser'


// const db = new PouchDB('http_cache')


/**
 * @typedef {object} HTTPOptions
 * @prop {string} baseURL Base URL
 * @prop {object|function} headers Headers
 * @prop {boolean} cache Use cache
 */

/**
 * Sends XHR requests to API endpoint
 */
export default class HTTP {

    // _baseURL = null
    // _cache = true
    // _headers = null

    /**
     * HTTP Constructor
     * @param {?HTTPOptions} options 
     */
    constructor(options = {}){
        this._baseURL = localStorage.getItem('HTTP_BASE_URL') || options.baseURL
        this._cache = options.cache || true
        this._headers = options.headers || null

        if(localStorage.getItem('HTTP_CACHE') === 'true'){
            this._cache = true
        }
        else if(localStorage.getItem('HTTP_CACHE') === 'false'){
            this._cache = false
        }
    }

    /**
     * HTTP GET request
     * @param {string} url URL
     * @param {?object} options Options
     */
    get(url, data = null, options = {}){
        return this._request('get', url, data, options)
    }

    /**
     * HTTP POST request
     * @param {string} url URL
     */
    post(url, data = null, options = {}){
        return this._request('post', url, data, options)
    }

    /**
     * HTTP DELETE request
     * @param {string} url URL
     */
    delete(url, data = null, options = {}){
        return this._request('delete', url, data, options)
    }

    /**
     * HTTP PUT request
     * @param {string} url URL
     */
    put(url, data = null, options = {}){
        return this._request('put', url, data, options)
    }

    /**
     * HTTP PATCH request
     * @param {string} url URL
     */
    patch(url, data = null, options = {}){
        return this._request('patch', url, data, options)
    }


    _request(method, url, data, options = {}){
        // const content = `${ method }${ this._baseURL }${ url }${ JSON.stringify(data) }`
        // const hash = md5(content).toString()
        // let doc = null

        return from(axios({
            url,
            method,
            data,
            params: method === 'get' ? data : null,
            baseURL: this._baseURL,
            headers: typeof this._headers === 'function' ? this._headers(this) : (this._headers || {}),
            ...options
        }))
        
        /*
        return Observable.create(observer => {
            const cachePromise = !this._cache ? Promise.resolve() : db.get(hash)
                .then((result) => {
                    doc = result
                    
                    if(doc && doc.response){
                        // console.log('[HTTP] CACHE HIT', result)
                        observer.next(doc.response)
                    }
                })
                .catch((error) => {
                    return null
                }) // Ignore if nothing is found
            
            cachePromise.then(() => axios({
                url,
                method,
                data,
                params: method === 'get' ? data : null,
                baseURL: this._baseURL,
                headers: typeof this._headers === 'function' ? this._headers(this) : (this._headers || {}),
                ...options
            }))
            .then((resp) => {
                db
                .remove(doc || hash)
                .catch(error => {
                    if(error.status !== 404){ // missing
                        // console.error('[HTTP] Failed to delete old cache', error);
                    }
                })
                .then(() => db.put({
                    _id: hash,
                    _rev: "1",
                    url,
                    response: resp.data,
                }, { force: true }))
                // .then(result => console.log('Cache save result', result))
                .catch(error => {
                    // console.error('[HTTP] Failed to save cache data', error);
                })

                if(doc !== null && md5(JSON.stringify(resp.data)).toString() === md5(JSON.stringify(doc.response)).toString()){
                    // console.log('[HTTP] Nothing has changed from cache');
                    return observer.complete()
                }

                observer.next(resp.data)
                observer.complete()
                return resp.data
            })
            .catch(error => observer.error(error))
        })
        */
    }
}