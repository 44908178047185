import { get } from 'lodash'

let locales = ['en', 'fr', 'ar']
let localesData = get(window, 'localesData', {})

const setLocalesData = (data) => localesData = data
const getLocalesData = () => localesData
const addLocalesData = (data) => localesData = {...localesData, ...data}

const getLocale = () => {
    const lsLocale = localStorage.getItem('locale')
    const navLocale = window.navigator && window.navigator.languages && window.navigator.languages.map(lang => lang.substr(0, 2))[0]
    const locale = lsLocale || navLocale

    return locales.includes(locale) ? locale : locales[0]
}

const setLocales = (newLocales) => locales = newLocales

const format = (format, ...args) => {
    return format.replace(/{(\d+)}/g, (match, number) => {
      return typeof args[number] != 'undefined' ? args[number] : match
    })
}

const translate = (string, data = [], defaultValue = null) => {
    let defaultVal = defaultValue || string
  
    let translation = get(localesData, string, null)
    return translation !== null && typeof translation === 'string' ? format(translation + '', data) : format(defaultVal, data)
}

const __ = translate

export {
    getLocale,
    setLocales,
    setLocalesData,
    getLocalesData,
    addLocalesData,
    translate,
    __
}

export default __