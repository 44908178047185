import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import styled, { injectGlobal } from 'styled-components';
import { compact } from 'lodash';
import AppContext from './context';
import Auth from './auth';
import Layout from './layout';
// import '../assets/css/theme.scss'

class App extends Component {
	state = {
		user: undefined,
	};

	componentWillMount() {
		const { app } = this.props;
		this.authSub = app.auth.subscribe(async user => {
			if (this.state.user === null && user && this.props.onAuth) {
				await this.props.onAuth(user);
			}
			this.setState({ user });
		});
		this.titleSub = app.store.watch('title').subscribe(title => {
			document.title = compact([
				app.store.get('title'),
				app.store.get('name'),
			]).join(' - ');
		});
	}

	componentWillUnmount() {
		this.authSub.unsubscribe();
		this.titleSub && this.titleSub.unsubscribe();
	}

	render() {
		let children = this.props.children;

		if (this.state.user === undefined) {
			children = null;
		} else if (this.props.authRequired === true) {
			children = this.state.user
				? this.props.children
				: React.createElement(this.props.authComponent || Auth);
		}

		return (
			<div className={`App ${this.props.className}`}>
				<BrowserRouter>
					<AppContext.Provider value={this.props}>
						{children || <Layout location={this.props.location} />}
					</AppContext.Provider>
				</BrowserRouter>
			</div>
		);
	}
}

App.defaultProps = {
	app: null,
	authRequired: true,
	authComponent: null,
	colorPrimary: '#0E82C2',
	routes: [],
	onAuth: null,
};

// Build global styles
injectGlobal`
    html, body, #root {
        height: 100%;
        width: 100%;
    }
`;

export default styled(App)`
	height: 100%;
`;
