import AF from './appfactory/app';
import { setLocales } from './appfactory/i18n';
import { get } from 'lodash';

setLocales(['fr', 'en']);
window.GOOGLE_MAPS_KEY = 'AIzaSyAQAqPMn8QUxc0YPAt3O7CKKJCvNTvmiMs';

const app = new AF({
	name: 'Master Audit',
	serverURL:
		process.env.NODE_ENV !== 'production'
			? 'http://localhost:4000'
			: 'https://api.mastermycity.com',
	graphql: {
		url:
			process.env.NODE_ENV !== 'production'
				? 'http://localhost:4000'
				: 'https://api.mastermycity.com',
		subscriptions:
			process.env.NODE_ENV !== 'production'
				? 'ws://localhost:4000'
				: 'wss://api.mastermycity.com',
		// cacheStorage: window.localStorage,
		fetchPolicy: 'network-only',
		onError: error => {
			if (get(error, 'graphQLErrors[0].name') === 'UNAUTHORIZED') {
				localStorage.removeItem('token');
				window.location.reload(true);
			}

			if (process.env.NODE_ENV !== 'production') {
				console.log('GRAPHQL ERROR', error);
			}
		},
	},

	// users: [ // Remove this in production
	// 	{
	// 		email: 'demo@masteraudit.net',
	// 		password: '000000',
	// 		firstname: 'Master',
	// 		lastname: 'Audit'
	// 	}
	// ]
});

export default app;
