import { ReplaySubject } from 'rxjs';
import { get } from 'lodash';
import jwtDecode from 'jwt-decode';
import HTTP from '../http';
import Graphql from '../graphql';

const meFields = `
    id
    email
    firstname
    lastname
    phone
    picture {
        id
        thumb
    }
    roles {
        id
        name
        permissions
    }
`;

/**
 * @typedef {object} AuthOptions
 * @param {string} serverURL Server URL
 */

class Auth {
	subject = new ReplaySubject(1);
	user = false;
	_http = false;
	token = null;

	/**
	 * @param {AuthOptions} config Auth configuration
	 */
	constructor(config) {
		this._http = new HTTP({
			baseURL: config.serverURL,
			cache: false,
		});

		// Handle authentication with access token from URL
		const accessTokenIndex = window.location.href.indexOf('access_token=');
		if (accessTokenIndex > -1) {
			const token = window.location.href.substr(accessTokenIndex + 13);
			localStorage.setItem('token', token);
			window.location.href = window.location.origin;
		}

		this._gql = new Graphql(config.graphql);

		// Load token
		const token = localStorage.getItem('token');
		// console.log('token', token);
		if (token) {
			this._handleAuth({ token });
		} else {
			this._setUser(null);
		}
	}

	_handleAuth({ token }) {
		const decoded = jwtDecode(token);

		// Handle token expiration
		const expiration = decoded.exp;
		const now = Math.round(Date.now() / 1000);
		const expiresAt = expiration - now;

		const handleExpiration = () => {
			if (expiresAt < 0) {
				return this._tokenExpired();
			}
		};
		handleExpiration();

		if (this.expirationInterval) {
			clearInterval(this.expirationInterval);
		}
		this.expirationInterval = setInterval(handleExpiration, 15 * 1000);

		this.token = token;
		localStorage.setItem('token', token);
		this._setUser(decoded.data);

		// Get a fresh user profile
		this._gql.headers['Authorization'] = `Bearer ${token}`;
		this._gql
			.query(
				`{
            me {
                ${meFields}
            }
        }`
			)
			.then(({ data: { me } }) => {
				this._setUser(me);
				// console.log('USER PROFILE', me);
			})
			.catch(error => {});
	}

	_tokenExpired() {
		// console.log('Token expired')
		localStorage.removeItem('token');
		this._setUser(null);
	}

	login(email, password) {
		localStorage.setItem('email', email);
		return this._http
			.post(`/auth/login`, { email, password })
			.toPromise()
			.then(result => {
				if (!get(result, 'data.error')) {
					this._handleAuth(result.data);
				}

				return result.data;
			});
	}

	logout() {
		localStorage.removeItem('token');
		// window.location.href = '/'
		return this._setUser(null);
	}

	forgot(email) {
		return this._http
			.post(`/auth/forgot`, { email })
			.toPromise()
			.then(({ data }) => data);
	}

	register(data) {
		return this._http
			.post(`/auth/register`, data)
			.toPromise()
			.then(result => {
				if (get(result, 'data.token')) {
					this._handleAuth(result.data);
				}

				return result.data;
			});
	}

	updatePassword(oldPassword, newPassword) {}

	updateProfile(data = {}) {
		return this._gql
			.mutate(
				`mutation updateProfile($userData: UserInput!) {
            updateProfile(data: $userData) {
                ${meFields}
            }
        }`,
				{ variables: { userData: data } }
			)
			.then(result => {
				this._setUser(result.data.updateProfile);
				return result;
			});
	}

	isAuthenticated() {
		return this.user !== null;
	}

	subscribe() {
		return this.subject.subscribe(...arguments);
	}

	_setUser(user) {
		this.user = user;
		this.subject.next(user);
	}
}

export default Auth;
