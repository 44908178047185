import React, { Component } from 'react';
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { flatten } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faAngleRight } from '@fortawesome/free-solid-svg-icons'

import AppContext from './context'
import Observable from './observable'

class Breadcrumb extends Component {
    render() {
        
        return (
            <AppContext.Consumer>
                { (ctx) => (
                    <Observable source={ctx.app.store.watch('breadcrumb')} loader={null}>
                        { (bcItems) => !bcItems ? null : (
                            <ul className={this.props.className}>
                                <li>
                                    <Link to="/">
                                        <FontAwesomeIcon icon={faHome} />
                                    </Link>
                                </li>
                                { flatten(bcItems.map((item, index) => [
                                    <li key={`icon-${index}`}>
                                        <FontAwesomeIcon icon={faAngleRight} colo='#ccc' />
                                    </li>,
                                    <li key={`link-${index}`}>
                                        <Link to={item.href}>
                                            { item.label }
                                        </Link>
                                    </li>
                                ])) }
                            </ul>
                        ) }
                    </Observable>
                ) }
            </AppContext.Consumer>
        );
    }
}

export default styled(Breadcrumb)`
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 0.9rem;

    li {
        display: inline-block;
        margin-right: 10px;
    }

    a, svg {
        color: #AEB2B7;
    }

    a:hover {
        color: #000;
        text-decoration: none;
    }

    li:last-child a {
        color: #000;
    }
`;