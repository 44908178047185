import HTTP from '../http';
import Store from '../store';
import Auth from '../auth';
import AuthLocal from '../auth/local';
import GraphQL from '../graphql';
import { startWith } from 'rxjs/operators';

/**
 * @typedef {object} AppOptions
 * @prop {string} name App Name
 * @prop {string} serverURL Server URL
 */

/**
 * AppFactory App
 */
class App {
	/**
	 * Constructor
	 * @param {AppOptions} options
	 */
	constructor(options) {
		this.store = new Store({
			name: options.name,
		});

		this.http = new HTTP({
			baseURL: options.serverURL,
		});

		this.auth = new Auth({
			serverURL: options.serverURL,
			graphql: options.graphql,
		});

		if (options.users && options.users.length > 0) {
			this.auth = new AuthLocal({
				users: options.users,
			});
		}

		this.gql = null;
		if (options.graphql) {
			this.gql = new GraphQL(options.graphql);

			this.auth.subject
				.pipe(startWith(this.auth.user))
				.subscribe(user => {
					if (user) {
						// console.log('USER', user);
						this.gql.headers = {
							Authorization: 'Bearer ' + this.auth.token,
						};
					} else {
						// console.log('USER', null);
						this.gql.headers = {};

						// Clear storage
						this.gql.client.clearStore();
					}
				});
		}
	}
}

export default App;
