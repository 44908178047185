import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { from } from 'rxjs'

import Loading from './loading'

class Observable extends Component {
    constructor(props){
        super(props)
    
        this.state = {
            loading: true,
            error: null
        }
    }
    
    
    componentWillMount(){
        // console.log('Observable did mount');
        let { source } = this.props
        if(source instanceof Promise){
            source = from(source)
        }

        this.sub = source.subscribe(
            data => {
                // console.log('Observable data', data);

                this.setState({
                    loading: false,
                    error: null,
                    data
                })
            },
            error => {
                // console.error('Failed to load data from observable', error)

                this.setState({
                    loading: false,
                    error
                })
            }
        )
    }
    
    
    componentWillUnmount(){
        this.sub.unsubscribe()
    }


    render(){
        if(this.state.loading){
            return this.props.loader
        }
        else if(this.state.error !== null){
            return this.props.error
        }
        return this.props.children(this.state.data)
    }
}


Observable.propTypes = {
    source: PropTypes.object,
    loader: PropTypes.element,
    error: PropTypes.element
}


Observable.defaultProps = {
    source: null,
    loader: <Loading />,
    error: null
}


export default Observable