import { ReplaySubject } from 'rxjs'
import { get, find } from 'lodash'



/**
 * @typedef {object} AuthOptions
 * @param {string} serverURL Server URL
 */


class Auth {
    subject = new ReplaySubject(1)
    user = false
    _http = false

    /**
     * @param {AuthOptions} config Auth configuration
     */
    constructor(config){
        this.options = config

        // Load user
        const user = localStorage.getItem('user')
        if(user){
            this._setUser(JSON.parse(user))
        }
        else {
            this._setUser(null)
        }
    }


    login(email, password){
        const user = find(this.options.users, item => item.email === email && item.password === password)

        return new Promise((resolve, reject) => {
            if(!user){
                resolve({error: 'Email ou mot de passe invalide(s)'})
            }
            else {
                localStorage.setItem('user', JSON.stringify(user))
                this._setUser(user)
                resolve(user)
            }
        })
    }

    logout(){
        localStorage.removeItem('user')
        return this._setUser(null)
    }

    forgot(email){
        return this._http
            .post(`/auth/forgot`, {email})
            .toPromise()
            .then(({data}) => data)
    }

    register(data){
        return this._http
            .post(`/auth/register`, data)
            .toPromise()
            .then(result => {
                if(get(result, 'data.token')){
                    this._handleAuth(result.data)                    
                }

                return result.data
            })
    }


    updatePassword(oldPassword, newPassword){

    }


    updateProfile(data){
        
    }


    isAuthenticated(){
        return this.user !== null
    }

    subscribe(){
        return this.subject.subscribe(...arguments)
    }

    _setUser(user){
        this.user = user
        this.subject.next(user)
    }
}

export default Auth