import React from 'react'
import styled from 'styled-components'

const StyledLoading = styled.div`
    margin: 20px auto;
    position: relative;
    
    & {
        width: 70px;
        text-align: center;
    }

    & > div {
        width: 18px;
        height: 18px;
        background-color: var(--primary);

        border-radius: 100%;
        display: inline-block;
        -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    & .bounce1 {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }

    & .bounce2 {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }

    @-webkit-keyframes sk-bouncedelay {
        0%, 80%, 100% { -webkit-transform: scale(0) }
        40% { -webkit-transform: scale(1.0) }
    }

    @keyframes sk-bouncedelay {
        0%, 80%, 100% { 
            -webkit-transform: scale(0);
            transform: scale(0);
        } 40% { 
            -webkit-transform: scale(1.0);
            transform: scale(1.0);
        }
    }
`

const Loading = (props) => {
    if (props.error) {
        return <div>Error!</div>
    }
    else if (props.timedOut) {
        return <div>Timeout</div>
    }
    else if (props.pastDelay || Object.keys(props) < 1) {
        return (
            <StyledLoading className="spinner loading">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
            </StyledLoading>
        )
    }
    else {
        return null
    }
}

export default Loading