import React, { Component } from 'react';
import { Route, Switch, Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fromEvent } from 'rxjs';

import AppContext from './context.js';
import Loadable from './loadable';
import logo from '../assets/img/logo.svg';
import avatar from '../assets/img/avatar.svg';
import Breadcrumb from './breadcrumb';
import Observable from './observable';

class Layout extends Component {
	state = {
		sidebarExpanded: false,
	};

	componentWillMount() {
		this.clickSub = fromEvent(window, 'click').subscribe(e => {
			if (
				e.target.className !== 'navbar-toggler-icon' &&
				this.state.sidebarExpanded
			) {
				this.setState({ sidebarExpanded: false });
			}
		});
	}

	componentWillUnmount() {
		this.clickSub.unsubscribe();
	}

	_renderMenu(menu, className = 'navbar-nav') {
		if (!menu) {
			return null;
		}

		if (!Array.isArray(menu)) {
			return menu;
		}

		return (
			<ul className={className}>
				{menu.map((item, index) => {
					let icon = item.icon;
					if (
						typeof item.icon === 'string' ||
						Array.isArray(item.icon)
					) {
						icon = <FontAwesomeIcon icon={item.icon} />;
					}

					return (
						<li
							className={`nav-item`}
							key={`menu-${index}-${item.label}`}
						>
							<NavLink
								exact={item.href === '/'}
								className="nav-link"
								to={item.href}
							>
								{icon} {item.label}
							</NavLink>
						</li>
					);
				})}
			</ul>
		);
	}

	hideSidebar = e => {
		e.preventDefault();
		this.setState({ sidebarExpanded: false });
	};

	showSidebar = e => {
		e.preventDefault();
		this.setState({ sidebarExpanded: true });
	};

	toggleSidebar = e => {
		e.preventDefault();
		this.setState({ sidebarExpanded: !this.state.sidebarExpanded });
	};

	render() {
		return (
			<AppContext.Consumer>
				{ctx => (
					<div
						className={`layout ${
							this.state.sidebarExpanded ? 'sidebar-expanded' : ''
						}`}
					>
						<nav className="navbar navbar-expand-md fixed-top navbar-dark bg-primary">
							<Link className="navbar-brand" to="/">
								<img src={ctx.logo || logo} alt="" />
								<span className="ml-2 d-none d-md-inline-block">
									{ctx.app.store.get('name')}
								</span>
							</Link>

							<button
								className="navbar-toggler"
								type="button"
								onClick={this.toggleSidebar}
							>
								<span className="navbar-toggler-icon" />
							</button>

							<div className="collapse navbar-collapse">
								{this._renderMenu(ctx.menuHeader)}

								{ctx.menuHeaderUser || (
									<ul className="navbar-nav user-menu ml-auto">
										<li className="nav-item dropdown">
											<a
												href={(() => '#')()}
												className="nav-link dropdown-toggle"
												id="userDropdown"
												role="button"
												data-target="#"
												data-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false"
											>
												<img
													src={ctx.avatar || avatar}
													alt=""
												/>{' '}
												{ctx.app.auth.user.firstname
													? `${
															ctx.app.auth.user
																.firstname
													  } ${
															ctx.app.auth.user
																.lastname
													  }`
													: ctx.app.auth.user.email}
											</a>

											<div
												className="dropdown-menu dropdown-menu-right"
												aria-labelledby="userDropdown"
												style={{ position: 'absolute' }}
											>
												{ctx.menuUser || (
													<React.Fragment>
														<NavLink
															exact
															className="dropdown-item"
															to="/profile"
														>
															Profile
														</NavLink>
														<div className="dropdown-divider" />
														<a
															href="/"
															className="dropdown-item"
															onClick={e => {
																e.preventDefault();
																ctx.app.auth.logout();
															}}
														>
															Logout
														</a>
													</React.Fragment>
												)}
											</div>
										</li>
									</ul>
								)}
							</div>
						</nav>

						<div id="main">
							{ctx.menuSidebar && (
								<div id="sidebar">
									{this._renderMenu(
										ctx.menuSidebar,
										'nav flex-column flex-grow-1'
									)}
								</div>
							)}

							<div id="content" className="container-fluid">
								<div className="row">
									<div className="col-sm-6 mb-3">
										{ctx.breadcrumb || <Breadcrumb />}
									</div>
									<div className="col-sm-6 mb-3 text-right">
										<Observable
											loader={null}
											source={ctx.app.store.watch(
												'pageactions'
											)}
										>
											{actions => actions}
										</Observable>
									</div>
								</div>

								<Switch>
									{ctx.routes.map(route =>
										React.createElement(Route, {
											key: `route-${route.path}`,
											...route,
										})
									)}
									<Route
										component={Loadable(() =>
											import('./404')
										)}
									/>
								</Switch>
							</div>
						</div>

						{ctx.footer}
						<div className="layout-overlay" />
					</div>
				)}
			</AppContext.Consumer>
		);
	}
}

export default Layout;
