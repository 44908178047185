import React from 'react'
import Loadable from 'react-loadable'
import Loading from './loading'

const defaultRender = (loaded, props) => {
    const Comp = loaded.default
    return <Comp {...props} />
}

export default (Component, render = defaultRender) => Loadable({
    loader: typeof Component === 'function' ? Component : () => Component,
    loading: Loading,
    render
})